<template>
  <div>
    <Loader :visible="isLoading" />

    <div>
      <v-row>
        <v-col cols="3">
          <div class="tway-title tway-label text--tway-violet">Nombre del plan</div>
        </v-col>
        <v-col>
          {{ `${planConstants.PlanType[subscription.plan.type]} - ${subscription.plan.name}` }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <div class="tway-title tway-label text--tway-violet">Precio</div>
        </v-col>
        <v-col>
          {{ `${subscription.plan.currency} ${subscription.plan.price}` }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="3">
          <div class="tway-title tway-label text--tway-violet">Período de suscripción</div>
        </v-col>
        <v-col>
          {{ planConstants.SubscriptionPeriod[subscription.period] }}
        </v-col>
      </v-row>
      <v-row v-if="subscription.paymentType">
        <v-col cols="3">
          <div class="tway-title tway-label text--tway-violet">Tipo de pago</div>
        </v-col>
        <v-col>
          {{ planConstants.PaymentType[subscription.paymentType] }}
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <div class="tway-title tway-label text--tway-violet">Pagos realizados</div>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12">
          <v-data-table
            :headers="transactionsTableHeaders"
            :items="subscription.payments"
            :items-per-page="5"
            class="elevation-0"
          >
            <template v-slot:[`item.paymentDate`]="{ item }">
              {{ item.paymentDate | formatDateTime }}
            </template>
            <template v-slot:[`item.amount`]="{ item }"> USD $ {{ item.amount | numeral("0,0") }} </template>
            <template v-slot:[`item.paymentMethod`]="{ item }">
              {{ planConstants.PaymentMethod[item.paymentMethod] }}
            </template>
          </v-data-table>
        </v-col>
      </v-row>
      <div class="d-flex">
        <v-spacer />
        <v-btn depressed rounded color="primary" :to="{ name: 'subscribe' }"> Contratar otro plan </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import Loader from "@/components/Loader";
import planConstants from "@/utils/planConstants.json";
import subscriptionService from "@/services/subscriptionService";

export default {
  name: "Subscription",

  components: {
    Loader,
  },

  data: () => ({
    isLoading: true,
    transactionsTableHeaders: [
      {
        text: "Orden de compra",
        align: "left",
        sortable: true,
        value: "buyOrder",
      },
      { text: "Fecha", align: "left", sortable: true, value: "paymentDate" },
      { text: "Valor", align: "left", sortable: true, value: "amount" },
      {
        text: "Método de pago",
        align: "left",
        sortable: true,
        value: "paymentMethod",
      },
    ],
    planConstants: planConstants,
    subscription: {
      paymentMethod: "",
      payments: [],
      plan: {
        name: "",
      },
    },
  }),

  created() {
    this.getSubscription();
  },

  methods: {
    getSubscription: function () {
      subscriptionService
        .getCurrentUserSubscription()
        .then((subscription) => (this.subscription = subscription))
        .catch((err) => this.$log.error("Unexpected error: ", err))
        .then(() => (this.isLoading = false));
    },
  },
};
</script>
